@import "minima";

body {
  border-top: 5px solid #337ab7;
  background: #fff url(/assets/bg-pattern.png);
  color: #636363;
  font-feature-settings: "kern", "liga", "pnum";
}

section {
  margin: 0;
  padding: 0;
  border: 0;
}

.hero-inner .dot {
  padding: 5px;
  border: 1px solid #dedede;
}

.dot {
  border-radius: 50%;
}
img, picture {
  margin: 0;
  max-width: 100%;
}


.hero-inner {
  text-align: center;
  display: inline-block;
  padding: 1.5em;
}

.hero-inner h1 {
  margin-top: .7em;
  font-size: 2.5rem;

}

h1 {
  font-size: 1.875rem;
}

h1 {
  font-family: "Open Sans","Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
  font-size: 1em;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 .75em;
}

.hero {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-top: 70px;
}

.hero-inner strong {
  font-weight: 700;
}

.hero-inner em {
  color: #337ab7;
}
.hero-inner h3 {
  font-weight: 300;
  margin: .8em 0 1.2em;
  font-size: 1.5rem;
}
em {
  font-style: italic;
}


.social {
  padding: 1.5em 0;
}

.social li  a {
  font-size: 2.0rem;
  letter-spacing: 1em;
}

ul, ol {
  margin: 0;
  list-style-position: inside;
  padding: 0 0 0 25px;
}
ol, ul {
  list-style: none;
}

.social li {
  display: inline-block;
  padding: 0 .6em;
}

